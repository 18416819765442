const data = [
    {
        icon: 'atomicDex',
        linkName: 'atomicDex'
    },
    {
        icon: 'dexTrade',
        linkName: 'dexTrade'
    }
]

export default data;
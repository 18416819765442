export const tabs = {
    TRADE: 'trade',
    NFT: 'nft',
    TOKENS: 'tokens'
}

export const curves = {
    EaseIn: 'ease-in',
    EaseOut: 'ease-out',
    EaseInOut: 'ease-in-out',
    Ease: 'ease',
  };